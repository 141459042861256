import Map from 'ol/Map';
import TileLayer from 'ol/layer/Tile';
import { ImageWMS, WMTS } from 'ol/source';
import { View } from 'ol';
import { Extent, getCenter, getWidth } from 'ol/extent';
import proj4 from 'proj4';
import { register } from 'ol/proj/proj4';
import { get as getProjection, Projection, ProjectionLike } from 'ol/proj';
import useGetCapabilities from './queries/useGetCapabilities';
import { useEffect, useReducer, useRef } from 'react';
import MapLayerDefinition from '../interfaces/MapLayerDefinition';
import ImageLayer from 'ol/layer/Image';
import { optionsFromCapabilities } from 'ol/source/WMTS';

proj4.defs('EPSG:31370', '+proj=lcc +lat_0=90 +lon_0=4.36748666666667 +lat_1=51.1666672333333 +lat_2=49.8333339 +x_0=150000.013 +y_0=5400088.438 +ellps=intl +towgs84=-106.8686,52.2978,-103.7239,-0.3366,0.457,-1.8422,-1.2747 +units=m +no_defs +type=crs');
register(proj4);

const getProjectionInfo = (projectionString: ProjectionLike) => {
	const projection = getProjection(projectionString) as Projection;
	const projectionExtent = [14697.3, 20939.01, 297193.15, 246456.18] as Extent;
	const resolutions = new Array(15);
	const matrixIds = new Array(resolutions.length);
	const size = getWidth(projectionExtent) / 256;
	for (let z = 0; z < matrixIds.length; ++z) {
		resolutions[z] = size / Math.pow(2, z);
		matrixIds[z] = z;
	}
	return {
		projection,
		matrixIds,
		resolutions,
		projectionExtent,
	};
};

const projection31370 = getProjectionInfo('EPSG:31370');
const newMap = new Map({
	controls: [],
	layers: [],
	target: 'map',
	view: new View(),
});

const useMap = (target: HTMLDivElement | null, mapLayerDefinitions: MapLayerDefinition[], activeLayers: string[]) => {
	const grbCapabilities = useGetCapabilities(mapLayerDefinitions);
	const map = useRef(newMap);
	const [, forceUpdate] = useReducer(x => x + 1, 0);

	useEffect(() => {
		map.current.on('change', forceUpdate);
		forceUpdate();
	}, []);

	useEffect(() => {
		if (!target) return;
		map.current.setTarget(target);
	}, [target]);

	useEffect(() => {
		if (!grbCapabilities.data || grbCapabilities.data.length === 0) return;
		map.current.setLayers([]);
		grbCapabilities.data.forEach((capability, index) => {
			if (!capability) return;
			if (mapLayerDefinitions[index].service === 'WMTS') {
				const options = optionsFromCapabilities(capability, {
					layer: mapLayerDefinitions[index].layer,
					matrixSet: mapLayerDefinitions[index].matrixSet,
				});
				if (!options) return;
				map.current.addLayer(new TileLayer({
					opacity: mapLayerDefinitions[index].opacity,
					source: new WMTS(options),
					visible: activeLayers.includes(mapLayerDefinitions[index].id),
					properties: mapLayerDefinitions[index],
				}));
				map.current.setView(new View({
					extent: options.tileGrid.getExtent(),
					resolutions: projection31370.resolutions,
					projection: projection31370.projection,
					center: getCenter(options.tileGrid.getExtent()),
					zoom: 1,
					maxZoom: 14,
				}));
			} else {
				map.current.addLayer(new ImageLayer({
					opacity: mapLayerDefinitions[index].opacity,
					source: new ImageWMS({
						url: capability.Service.OnlineResource,
						ratio: 1,
						params: {
							layers: mapLayerDefinitions[index].layer,
						},
					}),
					properties: mapLayerDefinitions[index],
					visible: activeLayers.includes(mapLayerDefinitions[index].id),
				}));
			}
		});

		// eslint-disable-next-line 
	}, [grbCapabilities.data]);

	useEffect(() => {
		map.current?.getLayers().forEach(layer => {
			const id = layer.get('id');
			layer.setVisible(activeLayers.includes(id));
		});
	}, [activeLayers]);
};


export default useMap;
