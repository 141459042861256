import { MutateFunction, useMutation } from '@tanstack/react-query';
import GenerateDocumentInterface from '../../interfaces/request/GenerateDocumentInterface';
import { api } from '../../index';
import { useState } from 'react';

const createDocument: MutateFunction<string, Error, GenerateDocumentInterface, string> = async (body) => {
	const response = await api.post('/api/document/generate', JSON.stringify(body), {
		headers: {
			'Content-Type': 'application/json',
		},
	});
	if (response.status !== 201) {
		throw new Error(response.data);
	}

	return response.headers['location'] ?? response.headers['Location'];
};

const useGenerateDocumentMutation = () => {
	const [generatedDocumentResponse, setGeneratedDocumentResponse] = useState<string>();
	const { isPending, mutate, isError } = useMutation({
		mutationFn: createDocument,
		onSuccess: data => {
			setGeneratedDocumentResponse(data);
		},
		onError: error => {
			setGeneratedDocumentResponse(error.message);
		},
	});

	const reset = () => {
		setGeneratedDocumentResponse(undefined);
	};

	return {
		generatedDocumentResponse,
		isError,
		isPending,
		mutate,
		reset,
	};
};

export default useGenerateDocumentMutation;
