import React, { useCallback, useEffect, useState } from 'react';
import NameAndId from '../interfaces/NameAndId';
import { Combobox, ComboboxOpenChangeData, ComboboxOpenEvents, Label, makeStyles, Option, OptionOnSelectData, SelectionEvents } from '@fluentui/react-components';

type Props = {
	label: string;
	placeholder: string;
	options: NameAndId[];
	onSearch: (searchTerm: string) => void;
	onSelectEntry: (entry: NameAndId) => void;
	selectedEntry?: NameAndId;
	required?: boolean;
};

const searchDelay: number = 200;

const useClasses = makeStyles({
	container: {
		flexDirection: 'column',
		gap: '2px',
		width: '100%',
	},
});

const AutofillSearchBar: React.FC<Props> = ({ placeholder, required, options, onSearch, label, onSelectEntry, selectedEntry }) => {
	const [searchTerm, setSearchTerm] = useState<string>('');
	const classes = useClasses();
	const [isOpen, setIsOpen] = useState(false);

	useEffect(() => {
		if (!selectedEntry) {
			setSearchTerm('');
			return;
		}
		setSearchTerm(selectedEntry.name);
	}, [selectedEntry]);

	useEffect(() => {
		const timeout = setTimeout(() => {
			onSearch?.(searchTerm);
		}, searchDelay);
		return () => clearTimeout(timeout);
	}, [searchTerm, onSearch]);

	const onSearchChange = useCallback<React.ChangeEventHandler<HTMLInputElement>>(event => {
		setSearchTerm(event.target.value);
	}, []);

	const onOptionSelect = (_: SelectionEvents, data: OptionOnSelectData) => {
		onSelectEntry(options.find(option => option.id === data.optionValue)!);
	};

	const onOpenChange = (_: ComboboxOpenEvents, data: ComboboxOpenChangeData) => {
		setIsOpen(data.open);
	};

	return (
		<div className={classes.container}>
			<Label required={required}>{label}</Label>
			<Combobox open={options.length > 0 && isOpen} onOpenChange={onOpenChange} clearable freeform onOptionSelect={onOptionSelect} placeholder={placeholder} value={searchTerm}
						 onChange={onSearchChange}>
				{options.map((option) => (
					<Option key={option.id} value={option.id}>
						{option.name}
					</Option>
				))}
			</Combobox>
		</div>
	);
};

export default AutofillSearchBar;
