import { useMsal } from '@azure/msal-react';
import React, { useMemo } from 'react';

type Props = {
	isUser: boolean;
};

const colorHash = (inputString: string) => {
	var sum = 0;
	for (let i = 0; i < inputString.length; i++) {
		sum += inputString.charCodeAt(i);
	}

	const r = ~~(
		(('0.' +
			Math.sin(sum + 1)
				.toString()
				.substring(6)) as any) * 256
	);
	const g = ~~(
		(('0.' +
			Math.sin(sum + 2)
				.toString()
				.substring(6)) as any) * 256
	);
	const b = ~~(
		(('0.' +
			Math.sin(sum + 3)
				.toString()
				.substring(6)) as any) * 256
	);

	const rgb = 'rgb(' + r + ', ' + g + ', ' + b + ')';
	const darkText = r * 0.299 + g * 0.587 + b * 0.114 > 186;
	return {
		color: rgb,
		useLightText: !darkText,
	};
};

const Badge: React.FC<Props> = ({ isUser }) => {
	const msal = useMsal();
	const accountName = msal.instance.getActiveAccount()?.username;
	const color = useMemo(() => colorHash(accountName ?? 'Unknown'), [accountName]);

	if (isUser) {
		return (
			<div className={`w-full justify-center items-center rounded-3xl ${color.useLightText ? 'text-white' : 'text-black'}`} style={{ backgroundColor: color.color }}>
				{msal.instance.getActiveAccount()?.name?.[0] ?? 'U'}
			</div>
		);
	}
	return <img alt="user" src={'logo_purple.png'} className="w-8 h-8" />;
};
export default Badge;
