import React, { useCallback, useEffect, useMemo, useState } from 'react';
import TemplateDocumentConfigurationResponseInterface from '../interfaces/response/TemplateDocumentConfigurationResponseInterface';
import useGenerateDocumentMutation from '../hooks/mutations/useGenerateDocumentMutation';
import BrandTemplateConfigurationResponse, { BrandTemplateType } from '../interfaces/response/BrandTemplateConfigurationResponse';
import SharepointDocumentTemplateConfigurationResponse from '../interfaces/response/SharepointDocumentTemplateConfigurationResponse';
import SharepointDocumentReference from '../interfaces/request/SharepointDocumentReference';
import NameAndId from '../interfaces/NameAndId';
import { Button, makeStyles, ProgressBar, Text, tokens } from '@fluentui/react-components';
import Stepper from '../components/Stepper';
import DocumentGenerationSelectProjectStep from '../components/document-generation/DocumentGenerationSelectProjectStep';
import DocumentGenerationSelectDocumentOptions from '../components/document-generation/DocumentGenerationSelectDocumentOptions';
import DocumentGenerationSelectDocumentContent from '../components/document-generation/DocumentGenerationSelectDocumentContent';
import { useTranslation } from 'react-i18next';
import { Carousel, CarouselButton, CarouselCard, CarouselSlider } from '@fluentui/react-carousel-preview';

const useClasses = makeStyles({
	root: {
		marginTop: tokens.spacingVerticalXXXL,
		flexDirection: 'column',
		flex: 1,
	},
	title: {
		fontSize: tokens.fontSizeBase500,
		fontWeight: tokens.fontWeightSemibold,
		marginBottom: tokens.spacingVerticalXXXL,
	},
	generatingDocumentContainer: {
		flex: 1,
		flexDirection: 'column',
		alignItems: 'center',
		justifyContent: 'center',
		width: '100%',
	},
	generatingDocumentSpinnerContainer: {
		width: '40em',
		flexDirection: 'column',
		alignItems: 'center',
	},
	carouselContainer: {
		flexDirection: 'column',
		marginLeft: tokens.spacingHorizontalXXXL,
		marginRight: tokens.spacingHorizontalXXXL,
		flex: 1,
		marginTop: tokens.spacingVerticalXXXL,
	},
	carouselButtonsContainer: {
		flexDirection: 'row',
		justifyContent: 'center',
		marginBottom: tokens.spacingVerticalXXXL,
	},
	carouselSlider: {
		minHeight: 0,
	},
	flex: {
		flex: 1,
	},
	newGenerationButton: {
		marginTop: tokens.spacingVerticalL,
	},
	column: {
		flexDirection: 'column',
		width: '20em',
	},
	errorText: {},
});

const DocumentsPage: React.FC = () => {
	const { t } = useTranslation();
	const classes = useClasses();
	const generateDocumentMutation = useGenerateDocumentMutation();
	const [activeWizardStep, setActiveWizardStep] = useState<number>(0);
	const [selectedCompany, setSelectedCompany] = useState<BrandTemplateConfigurationResponse>();
	const [selectedProject, setSelectedProject] = useState<NameAndId>();
	const [selectedClient, setSelectedClient] = useState<NameAndId>();
	const [selectedContact, setSelectedContact] = useState<NameAndId>();
	const [selectedDocument, setSelectedDocument] = useState<TemplateDocumentConfigurationResponseInterface>();
	const [selectedCheckboxOptionIds, setSelectedCheckboxOptionIds] = useState<string[]>([]);
	const [data, setData] = useState<any>({});
	const [templateTypes, setTemplateTypes] = useState<BrandTemplateType[]>([]);

	useEffect(() => {
		const newTypeList: BrandTemplateType[] = [];
		if (selectedContact) {
			newTypeList.push('Contact');
		}
		if (selectedClient) {
			newTypeList.push('Client');
		}
		if (selectedProject) {
			newTypeList.push('Project');
		}
		setTemplateTypes(newTypeList);
		setSelectedDocument(undefined);
		setData({});
	}, [selectedContact, selectedClient, selectedProject]);

	useEffect(() => {
		setSelectedContact(undefined);
	}, [selectedClient]);

	useEffect(() => {
		if (activeWizardStep === 0) {
			setSelectedCheckboxOptionIds([]);
			setData({});
		}
	}, [activeWizardStep]);

	const resetState = () => {
		setActiveWizardStep(0);
		setData({});
		setSelectedCheckboxOptionIds([]);
		setSelectedProject(undefined);
		setSelectedClient(undefined);
		setSelectedContact(undefined);
		generateDocumentMutation.reset();
	};

	const isNextDisabled = () => {
		if (activeWizardStep === 0) {
			return templateTypes.length === 0;
		}
		if (activeWizardStep === 1) {
			return !selectedDocument;
		}
		return false;
	};

	const generateDocument = (content: SharepointDocumentTemplateConfigurationResponse[]) => {
		if (!selectedCompany || !selectedDocument) return;
		const dataToSend = { ...data, RequestData: {} };
		if (selectedProject) dataToSend.RequestData.ProjectId = selectedProject.id;
		if (selectedClient) dataToSend.RequestData.ClientId = selectedClient.id;
		if (selectedContact) dataToSend.RequestData.ContactId = selectedContact.id;
		generateDocumentMutation.mutate({
			DocumentName: selectedDocument?.name,
			DocumentSections: content.map(item => ({
				DocumentId: item.sharepointDocumentId,
				DriveId: item.sharepointDriveId,
			} as SharepointDocumentReference)),
			ProjectId: selectedProject?.id,
			ContactId: selectedContact?.id,
			ClientId: selectedClient?.id,
			Data: dataToSend,
		});
	};

	const onCarouselIndexChange = (_: any, { index }: { index: number }) => {
		setActiveWizardStep(index);
	};

	const onSelectedCompanyChange = useCallback((company: BrandTemplateConfigurationResponse) => {
		setSelectedDocument(undefined);
		setSelectedCompany(company);
		setData({});
	}, [setSelectedCompany, setSelectedDocument]);

	const generationText = useMemo(() => {
		if (generateDocumentMutation.generatedDocumentResponse) {
			return generateDocumentMutation.isError ? t('documentsPage.failedGeneratingDocument') : t('documentsPage.generatedDocument');
		} else {
			return t('documentsPage.generatingDocument');
		}
	}, [generateDocumentMutation, t]);

	return (
		<div className={classes.root}>
			<Stepper currentStep={activeWizardStep} stepCount={3} />
			{generateDocumentMutation.isPending || !!generateDocumentMutation.generatedDocumentResponse ? (
				<div className={classes.generatingDocumentContainer}>
					<div className={classes.generatingDocumentSpinnerContainer}>
						<Text className={classes.title}>{generationText}</Text>
						{!generateDocumentMutation.generatedDocumentResponse && <ProgressBar thickness={'large'} />}
						{!!generateDocumentMutation.generatedDocumentResponse && (
							<div className={classes.column}>
								{generateDocumentMutation.isError ?
									<Text className={classes.errorText}>{generateDocumentMutation.generatedDocumentResponse}</Text> :
									<Button as={'a'} href={generateDocumentMutation.generatedDocumentResponse} target={'_blank'} size={'large'}
											  appearance={'primary'}>{t('documentsPage.navigateToSharepoint')}</Button>
								}
								<Button className={classes.newGenerationButton} appearance={'secondary'} size={'medium'} onClick={resetState}>{t('documentsPage.newGeneration')}</Button>
							</div>
						)}
					</div>
				</div>
			) : (
				<Carousel groupSize={1} className={classes.carouselContainer} onActiveIndexChange={onCarouselIndexChange} activeIndex={activeWizardStep}>
					<CarouselSlider className={classes.carouselSlider}>
						<CarouselCard>
							<DocumentGenerationSelectProjectStep
								setSelectedContact={setSelectedContact}
								selectedContact={selectedContact}
								selectedProject={selectedProject}
								selectedClient={selectedClient}
								setSelectedProject={setSelectedProject}
								setSelectedClient={setSelectedClient}
								setSelectedCompany={onSelectedCompanyChange}
							/>
						</CarouselCard>
						<CarouselCard>
							{selectedCompany && <DocumentGenerationSelectDocumentOptions
								selectedDocument={selectedDocument}
								types={templateTypes}
								selectedCompany={selectedCompany}
								setSelectedDocument={setSelectedDocument}
								selectedProjectName={selectedProject?.name}
								selectedContactName={selectedContact?.name}
								selectedClientName={selectedClient?.name}
								selectedOptionIds={selectedCheckboxOptionIds}
								setSelectedOptionIds={setSelectedCheckboxOptionIds}
								setData={setData}
							/>}
						</CarouselCard>
						<CarouselCard>
							{selectedCompany && selectedDocument && <DocumentGenerationSelectDocumentContent
								generateDocument={generateDocument}
								selectedCompany={selectedCompany}
								selectedDocument={selectedDocument}
								selectedOptionIds={selectedCheckboxOptionIds}
								selectedProjectName={selectedProject?.name}
								selectedContactName={selectedContact?.name}
								selectedClientName={selectedClient?.name}
							/>}
						</CarouselCard>
					</CarouselSlider>
					<div className={classes.flex} />
					<div className={classes.carouselButtonsContainer}>
						<CarouselButton navType={'prev'} type={'button'} appearance={'primary'}>
							{t('documentsPage.previous')}
						</CarouselButton>
						<div className={classes.flex} />
						{activeWizardStep < 2 && (
							<CarouselButton navType={'next'} type={'button'} iconPosition={'after'} appearance={'primary'} disabled={isNextDisabled()}>
								{t('documentsPage.next')}
							</CarouselButton>)
						}
					</div>
				</Carousel>

			)}
		</div>
	);
};

export default DocumentsPage;
