import { QueryFunction, QueryFunctionContext, useQuery } from '@tanstack/react-query';
import axios from 'axios';
import MapLayerDefinition from '../../interfaces/MapLayerDefinition';
import { WMSCapabilities, WMTSCapabilities } from 'ol/format';

const getCapabilities: QueryFunction<any[]> = async ({ queryKey }: QueryFunctionContext) => {
	const definitions = queryKey[0] as MapLayerDefinition[];
	const responses = await Promise.all(definitions.map(async definition => {
		const response = await axios.get<string>(`${definition.url}?request=GetCapabilities&version=1.3.0&service=${definition.service}`);
		if (response.status === 200) {
			return response.data;
		}
		return null;
	}));
	return responses.filter(response => !!response).map((response, index) => {
		const parser = definitions[index].service === 'WMTS' ? new WMTSCapabilities() : new WMSCapabilities();
		return parser.read(response!);
	})!;
};

const useGetCapabilities = (definitions: MapLayerDefinition[]) => {
	const { data, isLoading, error } = useQuery({
		queryKey: [definitions, 'getCapabilities'],
		queryFn: getCapabilities,
	});

	return {
		data,
		isLoading,
		error,
	};
};

export default useGetCapabilities;
