import React from 'react';

type Props = {
	title: string;
	colored?: boolean;
	disabled?: boolean;
	onClick: () => void;
};

const Button: React.FC<Props> = ({ onClick, title, colored, disabled }) => {
	return (
		<button
			disabled={disabled}
			onClick={onClick}
			className={`p-1 ${!colored ? 'text-black bg-white' : 'text-background bg-primary'} 
		rounded-xl flex flex-1 items-center justify-center ${disabled ? 'opacity-50' : 'hover:underline'}  shadow-lg`}>
			{title}
		</button>
	);
};

export default Button;
