import { QueryFunction, QueryFunctionContext, useQuery } from '@tanstack/react-query';
import { api } from '../../index';
import NameAndId from '../../interfaces/NameAndId';

const pageSize = 10;
const emptyResult: NameAndId[] = [];

const getContactsList: QueryFunction<NameAndId[]> = async ({ queryKey }: QueryFunctionContext) => {
	if (!queryKey[0]) {
		return emptyResult;
	}
	let url = `/api/tlo/contacts?searchTerm=${encodeURIComponent(queryKey[0] as string)}&count=${pageSize}&clientId=${queryKey[1]}`;
	const response = await api.get<NameAndId[]>(url);
	if (response.status === 200) {
		return response.data;
	}
	throw new Error(response.data as any);
};


const useGetContactsList = (searchTerm?: string, clientId?: string) => {
	const { data, isLoading, error } = useQuery({
		queryKey: [searchTerm, clientId, 'getContactsList'],
		queryFn: getContactsList,
		enabled: !!searchTerm && !!clientId,
	});

	return {
		contactsResults: data || emptyResult,
		isLoading,
		error,
	};
};

export default useGetContactsList;
