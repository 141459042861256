import React, { Fragment, useCallback, useContext, useMemo, useState } from 'react';
import ChatConfiguration from '../components/ChatConfiguration';
import Loading from '../components/Loading';
import { createPortal } from 'react-dom';
import PremadePromptsModal from '../components/PremadePromptsModal';
import ChatHistory from '../components/ChatHistory';
import { useTranslation } from 'react-i18next';
import ChatMessageList from '../components/ChatMessageList';
import { MsalContext } from '@azure/msal-react';
import { useAppDispatch, useAppSelector } from '../redux/useAppSelector';
import useChat from '../hooks/useChat';
import { editConversationInput, updateBotResponseStatus, updateConversationConfiguration } from '../redux/states/conversations/conversationsSlice';
import LoadingCircles from '../components/LoadingCircles';
import { ChatSessionConfigurationResponseInterface } from '../interfaces/response/ChatSessionResponseInterface';

const ChatPage = () => {
	const msal = useContext(MsalContext);
	const { t } = useTranslation();
	const { conversations, selectedId } = useAppSelector(state => state.conversations);
	const chat = useChat();
	const [showPremadePromps, setShowPremadePrompts] = useState<boolean>(false);
	const dispatch = useAppDispatch();
	const currentConversation = useMemo(() => conversations[selectedId], [conversations, selectedId]);
	const [isCreatingNewChat, setIsCreatingNewChat] = useState<boolean>(false);

	const onPromptChange: React.ChangeEventHandler<HTMLTextAreaElement> = useCallback((event) => {
		dispatch(editConversationInput({
			newInput: event.target.value,
			id: selectedId,
		}));
	}, [selectedId, dispatch]);

	const onSubmitPrompt = useCallback(() => {
		const configuration = conversations[selectedId]?.configuration;
		if (!configuration || msal.accounts.length === 0) {
			return;
		}
		chat.getResponse(selectedId, {
			Prompt: currentConversation.input,
			Configuration: {
				ScoringCutoff: configuration.scoringCutoff,
				InformationSources: configuration.informationSources,
				MaxResponseLength: configuration.maxResponseLength,
				DocumentCount: configuration.documentCount,
			},
		});
		dispatch(updateBotResponseStatus({ chatId: selectedId, status: 'Calling the kernel' }));
		dispatch(editConversationInput({
			id: selectedId,
			newInput: '',
		}));
	}, [conversations, msal.accounts, chat, selectedId, currentConversation.input, dispatch]);

	const onKeyDown: React.KeyboardEventHandler<HTMLTextAreaElement> = useCallback((event) => {
		if (event.key !== 'Enter') return;
		event.preventDefault();
		event.stopPropagation();
		if (currentConversation.input) {
			onSubmitPrompt();
		}
	}, [currentConversation.input, onSubmitPrompt]);

	const createNewChat = () => {
		setIsCreatingNewChat(true);
		chat.createChat().finally(() => {
			setIsCreatingNewChat(false);
		});
	};

	const showPremadePrompts = () => {
		setShowPremadePrompts(true);
	};

	const closePremadePrompts = () => {
		setShowPremadePrompts(false);
	};

	const selectPremadePrompt = (prompt: string) => {
		closePremadePrompts();
		dispatch(editConversationInput({
			id: selectedId,
			newInput: prompt,
		}));
	};

	const onChangeMessageFeedback = (positive: boolean, messageId: string) => {
		chat.updateChatFeedback({
			IsPositiveFeedback: positive,
			MessageId: messageId,
			ChatId: selectedId,
		});
	};

	const updateChatConfiguration = (newConfiguration: ChatSessionConfigurationResponseInterface) => {
		dispatch(updateConversationConfiguration({ configuration: newConfiguration, chatId: selectedId }));
	};

	return (
		<div className="flex-row flex-1 absolute left-0 top-0 right-0 md:bottom-0 bottom-14">
			{(!conversations[selectedId]?.configuration) ? (
				<div className="justify-center items-center flex-1">
					<Loading loading />
				</div>
			) : (
				<Fragment>
					<div className="flex-1  flex-col overflow-hidden ">
						<div className="flex-1 flex-col self-center overflow-hidden">
							<div className={'items-center justify-center text-black font-bold text-sm'}>
								{t('chatPage.underDevelopment')} - CHAT ID: {selectedId}
							</div>
							{isCreatingNewChat ? (<div className="flex-1 mt-4 mb-4 p-2 rounded-sm justify-center overflow-y-scroll md:w-[40vw]">
								<Loading />
							</div>) : (
								<Fragment>
									<ChatMessageList onChangeMessageFeedback={onChangeMessageFeedback} chatId={selectedId} chatMessages={currentConversation.messages} />
									<div className="flex-row justify-between">
										<button
											type="submit"
											className={`rounded-lg self-start bg-white shadow-lg pl-3 pr-3 text-sm pb-1 flex  flex-row pt-[2px] ${currentConversation.messages.length === 0 && 'opacity-0'}`}
											onClick={createNewChat}>
											<div className="material-symbols-outlined text-sm mr-2 mt-[1px]">delete</div>
											<div>{t('chatPage.newChat')}</div>
										</button>
										{currentConversation.botResponseStatus && (
											<div className={`flex-1 ml-6 text-sm items-center italic opacity-50 space-x-2`}>
												<LoadingCircles amount={5} changeInterval={100} />
												<span>{currentConversation.botResponseStatus}</span>
											</div>
										)}
										<div>
											<button className={`rounded-lg self-start bg-white shadow-lg pl-3 pr-3 text-sm pb-1 flex  flex-row pt-[2px]`} onClick={showPremadePrompts}>
												<div>{t('chatPage.showPrompts')}</div>
											</button>
										</div>
									</div>
									<div className="flex-row w-full justify-center align-middle  shadow-md bg-white mt-2 rounded-xl mb-8">
										<textarea
											className="m-2 pl-2 pr-2 flex-1 rounded-sm h-8 border-none outline-none resize-none pt-[3px]"
											value={currentConversation.input}
											onChange={onPromptChange}
											onKeyDown={onKeyDown}
											placeholder={t('chatPage.enterPrompt')}
										/>
										<button type="submit" className=" mr-8 rounded-sm font-bold text-primary disabled:opacity-25" onClick={onSubmitPrompt} disabled={!prompt}>
											{t('chatPage.send')}
										</button>
									</div>
								</Fragment>
							)}
						</div>
					</div>
					<div className="justify-between absolute right-0 top-0 w-1/6 bg-background flex-col mr-8 h-full pb-8">
						<div className="bg-white rounded-xl  shadow-lg  p-4 w-full">
							<ChatConfiguration configuration={conversations[selectedId].configuration} onConfigurationChange={updateChatConfiguration} />
						</div>
						<div className="bg-white rounded-xl  shadow-lg  p-4 w-full flex-1 mt-4  min-h-0">
							<ChatHistory />
						</div>
					</div>
				</Fragment>
			)}
			{showPremadePromps &&
				createPortal(
					<PremadePromptsModal onCancel={closePremadePrompts} onSelectPrompt={selectPremadePrompt} />,
					document.getElementById('root') as HTMLElement,
				)}
		</div>
	);
};

export default ChatPage;
