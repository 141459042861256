import { useAppDispatch, useAppSelector } from '../redux/useAppSelector';
import { ChatService } from '../services/ChatService';
import { ChatState } from '../redux/states/conversations/ChatState';
import {
	addConversation,
	addMessageToConversationFromUser,
	deleteConversation,
	setChatConfiguration,
	setConversations,
	setSelectedConversation,
	updateBotResponseStatus,
} from '../redux/states/conversations/conversationsSlice';
import ChatMessageResponseInterface from '../interfaces/response/ChatMessageResponseInterface';
import ChatRequestInterface from '../interfaces/request/ChatRequestInterface';
import { Conversations } from '../redux/states/conversations/ConversationsState';
import UpdateChatUserFeedbackRequestInterface from '../interfaces/request/UpdateChatUserFeedbackRequestInterface';
import { useMemo } from 'react';

const useChat = () => {
	const dispatch = useAppDispatch();
	const { conversations } = useAppSelector(state => state.conversations);
	const { activeUserInfo } = useAppSelector(state => state.app);
	const chatService = useMemo(() => new ChatService(), []);

	const createChat = async () => {
		if (!activeUserInfo) return;
		try {
			await chatService.createNewChatAsync(activeUserInfo.accessToken)
				.then((result) => {
					const newChat: ChatState = {
						id: result.chatSession.id,
						title: result.chatSession.title,
						messages: [result.initialMessage],
						input: '',
						botResponseStatus: undefined,
						createdOn: result.chatSession.createdOn,
						lastUpdatedTimestamp: new Date().getTime(),
						configuration: result.chatSession.configuration,
					};

					dispatch(addConversation(newChat));
					return newChat.id;
				});
		} catch (error: any) {
			const message = error instanceof Error ? error.message : String(error);
			console.log(message);
		}
	};

	const getDefaultChatConfiguration = async () => {
		if (!activeUserInfo) return;
		try {
			const chatConfiguration = await chatService.getChatConfigurationAsync(activeUserInfo.accessToken);
			dispatch(setChatConfiguration(chatConfiguration));
		} catch (error: any) {
			const message = error instanceof Error ? error.message : String(error);
			console.log(message);
			return [];
		}
	};

	const updateChatFeedback = async (body: UpdateChatUserFeedbackRequestInterface) => {
		if (!activeUserInfo) return;
		try {
			await chatService.updateUserFeedbackAsync(body, activeUserInfo.accessToken);
		} catch (error: any) {
			const message = error instanceof Error ? error.message : String(error);
			console.log(message);
		}
	};

	const getResponse = async (chatId: string, body: ChatRequestInterface) => {
		if (!activeUserInfo) return;
		const chatInput: ChatMessageResponseInterface = {
			chatId: chatId,
			timestamp: new Date().toISOString(),
			userId: activeUserInfo?.id as string,
			userName: activeUserInfo?.username as string,
			content: body.Prompt,
			authorRole: {
				label: 'user',
			},
		};

		dispatch(addMessageToConversationFromUser({ message: chatInput, chatId: chatId }));

		try {
			await chatService.generateResponseAsync(chatId, body, activeUserInfo.accessToken).then(() => {
				dispatch(updateBotResponseStatus({ chatId, status: undefined }));
			}).catch((e: any) => {
				throw e;
			});
		} catch (error: any) {
			const message = error instanceof Error ? error.message : String(error);
			console.log(message);
			dispatch(updateBotResponseStatus({ chatId, status: undefined }));
		}
	};

	const loadChats = async (): Promise<boolean> => {
		if (!activeUserInfo) return false;
		try {
			await getDefaultChatConfiguration();
			const chatSessions = await chatService.getChatSessionsAsync(activeUserInfo.accessToken);

			if (chatSessions.length > 0) {
				const loadedConversations: Conversations = {};
				await Promise.all(chatSessions.map(async chatSession => {
					const chatMessages = await chatService.getChatHistoryAsync(chatSession.id, activeUserInfo.accessToken);

					loadedConversations[chatSession.id] = {
						id: chatSession.id,
						title: chatSession.title,
						messages: chatMessages.reverse(),
						input: '',
						botResponseStatus: undefined,
						lastUpdatedTimestamp: new Date().getTime(),
						createdOn: chatSession.createdOn,
						configuration: chatSession.configuration,
					};
				}));

				dispatch(setConversations(loadedConversations));
				dispatch(setSelectedConversation(chatSessions[0].id));
			} else {
				// No chats exist, create first chat window
				await createChat();
			}
			return true;
		} catch (error: any) {
			const message = error instanceof Error ? error.message : String(error);
			console.log(message);
			return false;
		}
	};

	const deleteChat = async (chatId: string) => {
		if (!activeUserInfo) return;
		await chatService.deleteChatAsync(chatId, activeUserInfo.accessToken)
			.then(() => {
				dispatch(deleteConversation(chatId));

				if (Object.values(conversations).filter((c) => c.id !== chatId).length === 0) {
					createChat();
				}
			})
			.catch((error: any) => {
				const message = error instanceof Error ? error.message : String(error);
				console.log(message);
			});
	};

	return {
		createChat,
		getResponse,
		loadChats,
		deleteChat,
		updateChatFeedback,
		getDefaultChatConfiguration,
	};
};

export default useChat;