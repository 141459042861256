import React, { useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useAppSelector } from '../redux/useAppSelector';
import useMap from '../hooks/useMap';
import MapLayerDefinition from '../interfaces/MapLayerDefinition';
import { Button } from '@fluentui/react-components';


const mapLayerDefinitions: MapLayerDefinition[] = [
	{
		id: 'base',
		url: 'https://geo.api.vlaanderen.be/GRB/wmts',
		opacity: 1,
		matrixSet: 'BPL72VL',
		layer: 'grb_bsk',
		service: 'WMTS',
	},
	{
		id: 'other',
		url: 'https://geo.api.vlaanderen.be/VMSW/wms',
		opacity: 0.8,
		layer: 'Woningwbgb',
		service: 'WMS',
	},
];

const DebugPage: React.FC = () => {
	const mapRef = useRef<HTMLDivElement | null>(null);
	const [showOtherLayer, setShowOtherLayer] = useState(false);
	useMap(mapRef.current, mapLayerDefinitions, showOtherLayer ? ['base', 'other'] : ['base']);
	const { i18n } = useTranslation();
	const { activeUserInfo } = useAppSelector(state => state.app);

	return (
		<div className="flex-1 absolute left-0 top-0 right-0 md:bottom-0 bottom-14 justify-center items-center flex-col">
			<input type={'checkbox'} className={`w-5 h-5 accent-primary mt-1 mb-1 shrink-0`} checked={showOtherLayer} onChange={(evt) => setShowOtherLayer(evt.target.checked)} />
			<div className={'w-1/2 min-h-[40vh] mb-20 border-red border-2'} id={'map'} ref={mapRef} />
			<div className="w-1/2">
				<div className="justify-center items-center flex-1 break-all text-sm">{activeUserInfo?.accessToken}</div>
			</div>
			<div className={'flex-row space-x-2 h-10 mt-4'}>
				<Button onClick={() => i18n.changeLanguage('fr')} appearance={'primary'}>Change to FR</Button>
				<Button onClick={() => i18n.changeLanguage('nl')} appearance={'primary'}>Change to NL</Button>
				<Button onClick={() => i18n.changeLanguage('en')} appearance={'primary'}>Change to EN</Button>
			</div>
		</div>
	);
};

export default DebugPage;
