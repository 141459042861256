import { ChatState } from './ChatState';
import ChatConfigurationResponse from '../../../interfaces/response/ChatConfigurationResponseInterface';

export type Conversations = Record<string, ChatState>;

export type ConversationsState = {
	conversations: Conversations;
	selectedId: string;
	chatConfiguration: ChatConfigurationResponse;
}

export const initialState: ConversationsState = {
	conversations: {},
	selectedId: '',
	chatConfiguration: {
		availableSources: [],
		premadePrompts: [],
	},
};

export type ConversationInputChange = {
	id: string;
	newInput: string;
}