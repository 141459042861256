import { useEffect, useMemo, useState } from 'react';

type Props = {
	amount: number;
	changeInterval: number;
}

const LoadingCircles: React.FC<Props> = ({ amount, changeInterval }) => {
	const [selectedIndex, setSelectedIndex] = useState(0);
	const indexArray = useMemo(() => Array.from(Array(amount).keys()), [amount]);
	useEffect(() => {
		const interval = setInterval(() => {
			setSelectedIndex(prev => {
				if (prev + 1 >= amount) {
					return 0;
				}
				return prev + 1;
			});
		}, changeInterval);
		return () => {
			clearInterval(interval);
		};
	}, [changeInterval, amount]);

	return (
		<div className="flex-row space-x-1">
			{indexArray.map((index) => <div key={index} className={`bg-primary opacity-50 ${selectedIndex === index && 'bg-secondary opacity-100'} h-2 w-2 rounded-lg`} />)}
		</div>
	);
};

export default LoadingCircles;