import React, { createContext } from 'react';
import ChatMessageResponseInterface from '../interfaces/response/ChatMessageResponseInterface';

type ChatContextType = {
	[chatId: string]: {
		messages: ChatMessageResponseInterface[];
		botStatus?: string;
	}
}

const ChatContext = createContext<[ChatContextType, React.Dispatch<React.SetStateAction<ChatContextType>>] | null>(null);

export default ChatContext;
export type { ChatContextType };