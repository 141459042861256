import { Action, Dispatch, Middleware } from '@reduxjs/toolkit';
import { getSelectedChatID, RootState, StoreMiddlewareAPI } from '../store';
import ChatMessageResponseInterface from '../../interfaces/response/ChatMessageResponseInterface';
import { getOrCreateHubConnection } from './signalRConnection';

interface SignalRAction extends Action {
	payload: {
		message?: ChatMessageResponseInterface;
		userId?: string;
		id?: string;
	};
}

export const signalRMiddleware: Middleware<any, RootState, Dispatch<SignalRAction>> = (store: StoreMiddlewareAPI) => {
	return (next) => (action) => {
		// Call the next dispatch method in the middleware chain before performing any async logic
		const signalRAction = action as SignalRAction;
		const result = next(signalRAction);

		const hubConnection = getOrCreateHubConnection(store);

		switch (signalRAction.type) {
			case 'conversations/addMessageToConversationFromUser':
				hubConnection.invoke(
					'SendMessageAsync',
					getSelectedChatID(),
					store.getState().app.activeUserInfo?.id,
					signalRAction.payload.message,
				);
				break;
			case 'conversations/setConversations':
				Promise.all(
					Object.keys(signalRAction.payload).map(async (id) => {
						await hubConnection.invoke('AddClientToGroupAsync', id);
					}),
				);
				break;
			case 'conversations/addConversation':
				hubConnection.invoke('AddClientToGroupAsync', signalRAction.payload.id);
				break;
		}

		return result;
	};
};