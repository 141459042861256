import React from 'react';
import { useAppSelector } from '../redux/useAppSelector';
import { AppStateState } from '../redux/states/app/AppState';
import useSiteSetup from '../hooks/useSiteSetup';
import { useTranslation } from 'react-i18next';
import { Button, Dialog, DialogActions, DialogBody, DialogSurface, DialogTitle, DialogTrigger, makeStyles, Spinner, Text, tokens } from '@fluentui/react-components';
import { AppItemStatic, NavDrawer, NavDrawerBody, NavDrawerFooter, NavDrawerHeader, NavItem } from '@fluentui/react-nav-preview';
import { Bug20Filled, Bug20Regular, bundleIcon, Chat20Filled, Chat20Regular, Document20Filled, Document20Regular, LockClosed20Regular, PersonCircle32Regular } from '@fluentui/react-icons';
import { useMsal } from '@azure/msal-react';
import { Outlet, useNavigate } from 'react-router-dom';

const useClasses = makeStyles({
	loadingContainer: {
		flex: '1',
		justifyContent: 'center',
		alignItems: 'center',
		flexDirection: 'column',
	},
	loadingHeader: {
		fontWeight: 'bold',
		fontSize: tokens.fontSizeHero700,
		marginBottom: tokens.spacingVerticalXXXL,
	},
	container: {
		flex: 1,
		width: '100vw',
		height: '100vh',
		flexDirection: 'row',
		justifyContent: 'flex-start',
	},
	ueIcon: {
		alignSelf: 'center',
		width: '4em',
		height: '4em',
		marginTop: tokens.spacingVerticalS,
		marginBottom: tokens.spacingVerticalS,
	},
	logoutButton: {
		marginBottom: tokens.spacingVerticalS,
	},
});

const ChatIcon = bundleIcon(Chat20Filled, Chat20Regular);
const DocumentIcon = bundleIcon(Document20Filled, Document20Regular);
const BugIcon = bundleIcon(Bug20Filled, Bug20Regular);

const MainPage = () => {
	const classes = useClasses();
	const { t } = useTranslation();
	const appState = useAppSelector((state) => state.app);
	const msal = useMsal();
	const navigate = useNavigate();
	useSiteSetup();


	return appState.state !== AppStateState.Ready ? (
		<div className={classes.loadingContainer}>
			<Text className={classes.loadingHeader}>{(appState.state === AppStateState.Error) ? t('errorInitializing') : t('initializing')}</Text>
			{appState.state !== AppStateState.Error && <Spinner size={'huge'} />}
		</div>
	) : (
		<div className={classes.container}>
			<NavDrawer open type={'inline'} defaultSelectedValue={'documents'}>
				<NavDrawerHeader>
					<img className={classes.ueIcon} src={'logo_purple.png'} alt={'UE logo'} />
				</NavDrawerHeader>
				<NavDrawerBody>
					<AppItemStatic icon={<PersonCircle32Regular />}>
						{msal.accounts[0].name}
					</AppItemStatic>
					{process.env.NODE_ENV === 'development' && (
						<NavItem value={'chat'} icon={<ChatIcon />} as={'button'} onClick={() => navigate('/chat')}>
							{t('navigation.chat')}
						</NavItem>
					)}
					<NavItem value={'documents'} icon={<DocumentIcon />} as={'button'} onClick={() => navigate('/documents')}>
						{t('navigation.documents')}
					</NavItem>
					{process.env.NODE_ENV === 'development' && (
						<NavItem value={'debug'} icon={<BugIcon />} as={'button'} onClick={() => navigate('/debug')}>
							Debug
						</NavItem>
					)}
				</NavDrawerBody>
				<NavDrawerFooter>
					<Dialog modalType={'alert'}>
						<DialogTrigger disableButtonEnhancement>
							<Button icon={<LockClosed20Regular />} className={classes.logoutButton} appearance={'transparent'}>
								{t('navigation.signOut')}
							</Button>
						</DialogTrigger>
						<DialogSurface>
							<DialogBody>
								<DialogTitle>{t('navigation.signOutConfirmation')}</DialogTitle>
								<DialogActions>
									<DialogTrigger disableButtonEnhancement>
										<Button appearance="secondary">{t('no')}</Button>
									</DialogTrigger>
									<Button appearance="primary">{t('yes')}</Button>
								</DialogActions>
							</DialogBody>
						</DialogSurface>
					</Dialog>
				</NavDrawerFooter>
			</NavDrawer>
			<Outlet />
		</div>
	);
};

export default MainPage;
