import { QueryFunction, QueryFunctionContext, useQuery } from '@tanstack/react-query';
import TemplateConfigurationResponse from '../../interfaces/response/TemplateConfigurationResponse';
import { api } from '../../index';

const emptyResult: TemplateConfigurationResponse[] = [];

const getDocumentSectionInfoList: QueryFunction<TemplateConfigurationResponse[]> = async ({ queryKey }: QueryFunctionContext) => {
	if (!queryKey[0] || !queryKey[1]) {
		return emptyResult;
	}
	let url = `/api/document/templates/company/document-section-info-list?companyId=${encodeURIComponent(queryKey[0] as string)}&documentId=${queryKey[1]}`;
	const response = await api.get<TemplateConfigurationResponse[]>(url);
	if (response.status === 200) {
		return response.data;
	}
	throw new Error(response.data as any);
};

const useGetDocumentSectionInfoList = (companyId: string, documentId?: string) => {
	const { data, isLoading, error } = useQuery({
		queryKey: [companyId, documentId, 'getDocumentSectionInfoList'],
		queryFn: getDocumentSectionInfoList,
		enabled: !!companyId || !!documentId,
	});

	return {
		results: data || emptyResult,
		isLoading,
		error,
	};
};

export default useGetDocumentSectionInfoList;
