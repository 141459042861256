import { Configuration } from '@azure/msal-browser';

export const msalConfig: Configuration = {
	auth: {
		clientId: '5b2fda17-ef21-4f6d-8fd6-94253d92bf99',
		authority: 'https://login.microsoftonline.com/d180a7ea-1f9a-496d-b3ec-6689e2f85ad3',
		redirectUri: '/redirect',
		postLogoutRedirectUri: '/',
	},
	cache: {
		cacheLocation: 'sessionStorage',
		temporaryCacheLocation: 'sessionStorage',
		storeAuthStateInCookie: false,
	},
};

export const scopes: string[] = ['openid', 'offline_access', 'api://5b2fda17-ef21-4f6d-8fd6-94253d92bf99/Copilot.ReadWrite', 'MyFiles.Read', 'MyFiles.Write', 'Project.Read', 'AllSites.Manage'];
