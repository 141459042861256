import { configureStore, Dispatch, MiddlewareAPI } from '@reduxjs/toolkit';
import { ConversationsState } from './states/conversations/ConversationsState';
import { resetApp, resetStateReducer } from './rootReducer';
import { signalRMiddleware } from './message-relay/signalRMiddleware';
import { AppState } from './states/app/AppState';

export type StoreMiddlewareAPI = MiddlewareAPI<Dispatch, RootState>;
const store = configureStore({
	reducer: resetStateReducer,
	middleware: getDefaultMiddleware => getDefaultMiddleware().concat(signalRMiddleware),
});

export type RootState = {
	app: AppState,
	conversations: ConversationsState;
}

export const getSelectedChatID = (): string => {
	return store.getState().conversations.selectedId;
};

export const resetState = () => {
	store.dispatch(resetApp());
};

export default store;