import { ConversationInputChange, Conversations, ConversationsState, initialState } from './ConversationsState';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import ChatMessageResponseInterface from '../../../interfaces/response/ChatMessageResponseInterface';
import { ChatState } from './ChatState';
import ChatConfigurationResponse from '../../../interfaces/response/ChatConfigurationResponseInterface';
import { ChatSessionConfigurationResponseInterface } from '../../../interfaces/response/ChatSessionResponseInterface';

const conversationsSlice = createSlice({
	name: 'conversations',
	initialState,
	reducers: {
		setConversations: (state: ConversationsState, action: PayloadAction<Conversations>) => {
			state.conversations = action.payload;
		},
		editConversationInput: (state: ConversationsState, action: PayloadAction<ConversationInputChange>) => {
			const id = action.payload.id;
			state.conversations[id].input = action.payload.newInput;
		},
		setSelectedConversation: (state: ConversationsState, action: PayloadAction<string>) => {
			state.selectedId = action.payload;
		},
		addConversation: (state: ConversationsState, action: PayloadAction<ChatState>) => {
			const newId = action.payload.id;
			state.conversations = { [newId]: action.payload, ...state.conversations };
			state.selectedId = newId;
		},
		setChatConfiguration: (state: ConversationsState, action: PayloadAction<ChatConfigurationResponse>) => {
			state.chatConfiguration = action.payload;
		},
		deleteConversation: (state: ConversationsState, action: PayloadAction<string>) => {
			const keys = Object.keys(state.conversations);
			const id = action.payload;

			if (id === state.selectedId) {
				if (keys.length > 1) {
					state.selectedId = id === keys[0] ? keys[1] : keys[0];
				} else {
					state.selectedId = '';
				}
			}

			const { [id]: _, ...rest } = state.conversations;
			state.conversations = rest;
		},
		addMessageToConversationFromUser: (
			state: ConversationsState,
			action: PayloadAction<{ message: ChatMessageResponseInterface; chatId: string }>,
		) => {
			const { message, chatId } = action.payload;
			updateConversation(state, chatId, message);
		},
		addMessageToConversationFromServer: (
			state: ConversationsState,
			action: PayloadAction<{ message: ChatMessageResponseInterface; chatId: string }>,
		) => {
			const { message, chatId } = action.payload;
			updateConversation(state, chatId, message);
		},
		updateConversationConfiguration: (
			state: ConversationsState,
			action: PayloadAction<{ configuration: ChatSessionConfigurationResponseInterface; chatId: string }>,
		) => {
			const { configuration, chatId } = action.payload;
			state.conversations[chatId].configuration = configuration;
		},
		updateBotResponseStatus: (
			state: ConversationsState,
			action: PayloadAction<{ chatId: string; status: string | undefined }>,
		) => {
			const { chatId, status } = action.payload;
			const conversation = state.conversations[chatId];
			conversation.botResponseStatus = status;
		},
		updateMessageProperty: <K extends keyof ChatMessageResponseInterface, V extends ChatMessageResponseInterface[K]>(
			state: ConversationsState,
			action: PayloadAction<{
				property: K;
				value: V;
				chatId: string;
				messageIdOrIndex: string | number | undefined;
				updatedContent?: string;
			}>,
		) => {
			const { property, value, messageIdOrIndex, chatId, updatedContent } = action.payload;
			const conversation = state.conversations[chatId];
			const conversationMessage =
				typeof messageIdOrIndex === 'number'
					? conversation.messages[messageIdOrIndex]
					: conversation.messages.find((m) => m.id === messageIdOrIndex);

			if (conversationMessage) {
				conversationMessage[property] = value;
				if (updatedContent) {
					conversationMessage.content = updatedContent;
				}
			}
		},
	},
});

const updateConversation = (state: ConversationsState, chatId: string, message: ChatMessageResponseInterface) => {
	state.conversations[chatId].messages = [message, ...state.conversations[chatId].messages];
};

export const {
	addMessageToConversationFromUser,
	addMessageToConversationFromServer,
	setSelectedConversation,
	editConversationInput,
	setConversations,
	updateBotResponseStatus,
	addConversation,
	deleteConversation,
	setChatConfiguration,
	updateMessageProperty,
	updateConversationConfiguration,
} = conversationsSlice.actions;

export default conversationsSlice.reducer;