import { QueryFunction, QueryFunctionContext, useQuery } from '@tanstack/react-query';
import { api } from '../../index';
import { OptionTemplateConfiguration } from '../../interfaces/response/OptionSectionTemplateConfigurationResponse';

const emptyResult: OptionTemplateConfiguration[] = [];

const getDocumentApiOptionsList: QueryFunction<OptionTemplateConfiguration[]> = async ({ queryKey }: QueryFunctionContext) => {
	if (!queryKey[0]) {
		return emptyResult;
	}
	const response = await api.get<OptionTemplateConfiguration[]>(queryKey[0] as string);
	if (response.status === 200) {
		return response.data;
	}
	throw new Error(response.data as any);
};

const useGetDocumentApiOptionsList = (url?: string) => {
	const { data, isLoading, error } = useQuery({
		queryKey: [url, 'getDocumentApiOptionsList'],
		queryFn: getDocumentApiOptionsList,
		enabled: !!url,
	});

	return {
		results: data || emptyResult,
		isLoading,
		error,
	};
};

export default useGetDocumentApiOptionsList;
