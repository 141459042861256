import React, { useEffect, useRef } from 'react';

type Props = {
	value: number;
	onValueChange: (value: number) => void;
	useInteger?: boolean;
	minValue: number;
	maxValue: number;
};

const Slider: React.FC<Props> = ({ onValueChange, value, minValue, maxValue, useInteger }) => {
	const sliderRef = useRef<HTMLInputElement>(null);

	useEffect(() => {
		if (!sliderRef.current) return;

		const percentage = ((value - minValue) / (maxValue - minValue)) * 100;
		sliderRef.current.style.background = `linear-gradient(to right, #FFCD6F ${percentage}%, #323046 ${percentage}%)`;
	}, [value, sliderRef, maxValue, minValue]);

	const onChangeSliderValue: React.ChangeEventHandler<HTMLInputElement> = (evt) => {
		onValueChange(useInteger ? parseInt(evt.target.value) : parseFloat(evt.target.value));
	};

	return (
		<div className="w-full flex-row">
			<input
				type="range"
				ref={sliderRef}
				id="slider"
				min={minValue}
				max={maxValue}
				value={value}
				className="w-full opacity-70 hover:opacity-100 transition-opacity bg-black h-2 mt-2 rounded"
				step={useInteger ? 1 : 0.01}
				onChange={onChangeSliderValue}
			/>
			<div className="w-10 justify-end">{value}</div>
		</div>
	);
};

export default Slider;
