import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { ActiveUserInfo, AppState, AppStateState, initialState } from './AppState';

export const appSlice = createSlice({
	name: 'app',
	initialState,
	reducers: {
		setMaintenance: (state: AppState, action: PayloadAction<boolean>) => {
			state.isMaintenance = action.payload;
		},
		setActiveUserInfo: (state: AppState, action: PayloadAction<ActiveUserInfo>) => {
			state.activeUserInfo = action.payload;
		},
		setAppStateState: (state: AppState, action: PayloadAction<AppStateState>) => {
			state.state = action.payload;
		},
	},
});

export const {
	setActiveUserInfo,
	setMaintenance,
	setAppStateState,
} = appSlice.actions;

export default appSlice.reducer;