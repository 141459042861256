type Props = {
	title: string;
	content: string;
	onSelectPrompt: () => void;
	isSelected: boolean;
};

const PremadePromptCard: React.FC<Props> = ({ content, title, onSelectPrompt, isSelected }) => {
	const onClick = () => {
		onSelectPrompt();
	};

	return (
		<div className={`flex  w-64 shadow-md p-4 rounded-lg flex-col ${isSelected ? 'border-primary' : 'border-white'} border-2 max-h-60 cursor-pointer select-none`} onClick={onClick}>
			<div className={`flex-col    justify-start`}>
				<div className="flex-row items-center mb-2">
					<div className={`rounded-3xl border-2 border-primary w-6 h-6 mr-2 overflow-hidden p-0.5`}>
						<div className={`${isSelected ? 'bg-primary' : 'bg-white'} w-full h-full rounded-3xl`} />
					</div>
					<p className="font-semibold text-primary">{title}</p>
				</div>
				<p className="flex-row text-left">{content}</p>
			</div>
		</div>
	);
};

export default PremadePromptCard;
