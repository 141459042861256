import { useEffect } from 'react';
import { AppStateState } from '../redux/states/app/AppState';
import { setActiveUserInfo, setAppStateState } from '../redux/states/app/appSlice';
import { matchRoutes, useLocation, useNavigate } from 'react-router-dom';
import { api, routes } from '../index';
import { useTranslation } from 'react-i18next';
import useChat from './useChat';
import { useStore } from 'react-redux';
import { useMsalAuthentication } from '@azure/msal-react';
import { InteractionType } from '@azure/msal-browser';
import { scopes } from '../authConfig';
import { useAppSelector } from '../redux/useAppSelector';

const useSiteSetup = () => {
	const appState = useAppSelector((state) => state.app);
	const { result, acquireToken } = useMsalAuthentication(InteractionType.Redirect, {
		scopes: scopes,
		extraScopesToConsent: scopes,
	});
	const { i18n } = useTranslation();
	const chat = useChat();
	const store = useStore();
	const location = useLocation();
	const navigate = useNavigate();

	useEffect(() => {
		if (!result) return;
		switch (appState.state) {
			case AppStateState.SettingUserInfo:
				store.dispatch(setActiveUserInfo({
					accessToken: result.accessToken,
					username: result.account.username,
					id: `${result.account.localAccountId}.${result.account.tenantId}`,
				}));
				store.dispatch(setAppStateState(AppStateState.SettingLanguage));
				break;
			case AppStateState.SettingLanguage:
				i18n.changeLanguage((result.idTokenClaims as any).xms_tpl);
				store.dispatch(setAppStateState(AppStateState.LoadingChats));
				break;
			case AppStateState.LoadingChats:
				chat.loadChats().then(isSuccess => {
					if (!isSuccess) {
						store.dispatch(setAppStateState(AppStateState.Error));
					} else {
						store.dispatch(setAppStateState(AppStateState.Ready));
					}
				});
		}

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [result, i18n, store, appState]);

	useEffect(() => {
		const matches = matchRoutes(routes, location);
		if (!matches || (matches.length === 1 && matches[0].pathname === '/')) {
			navigate('/documents');
		}
	}, [location, navigate]);

	useEffect(() => {
		if (appState.activeUserInfo?.accessToken) {
			const interceptor = api.interceptors.request.use((value) => {
				value.headers.setAuthorization(`Bearer ${appState.activeUserInfo?.accessToken}`);
				return value;
			});
			return () => {
				api.interceptors.request.eject(interceptor);
			};
		}
	}, [appState.activeUserInfo]);

	useEffect(() => {
		const refreshInterval = setInterval(() => {
			acquireToken().then((result) => {
				if (!result) return;
				store.dispatch(setActiveUserInfo({
					accessToken: result.accessToken,
					username: result.account.username,
					id: `${result.account.localAccountId}.${result.account.tenantId}`,
				}));
			});
		}, 30 * 60 * 1000);
		return () => {
			clearInterval(refreshInterval);
		};

		// eslint-disable-next-line
	}, [acquireToken]);
};

export default useSiteSetup;