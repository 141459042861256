import React from 'react';
import TemplateConfigurationResponse from '../../interfaces/response/TemplateConfigurationResponse';
import OptionSectionTemplateConfigurationResponse from '../../interfaces/response/OptionSectionTemplateConfigurationResponse';
import DocumentGenerationOptionSection from './DocumentGenerationOptionSection';
import OptionSectionApiTemplateConfigurationResponse from '../../interfaces/response/OptionSectionApiTemplateConfigurationResponse';
import DocumentGenerationOptionSectionApi from './DocumentGenerationOptionSectionApi';

type Props = {
	sections: TemplateConfigurationResponse[];
	setSelectedOptionIds: React.Dispatch<React.SetStateAction<string[]>>;
	selectedOptionIds: string[];
	setData: React.Dispatch<React.SetStateAction<any>>;
}

const DocumentGenerationSectionsList: React.FC<Props> = ({ sections, selectedOptionIds, setSelectedOptionIds, setData }) => {
	return (
		<>
			{sections.map((section, index) => {
				if (section.type === 'OptionSection') {
					return <DocumentGenerationOptionSection key={index}
																		 section={section as OptionSectionTemplateConfigurationResponse}
																		 setSelectedOptionIds={setSelectedOptionIds}
																		 setData={setData}
																		 selectedOptionIds={selectedOptionIds} />;
				} else if (section.type === 'OptionSectionApi') {
					return <DocumentGenerationOptionSectionApi key={index}
																			 setData={setData}
																			 section={section as OptionSectionApiTemplateConfigurationResponse}
																			 setSelectedOptionIds={setSelectedOptionIds}
																			 selectedOptionIds={selectedOptionIds} />;
				}
				return null;
			})}
		</>
	);
};

export default DocumentGenerationSectionsList;