import { QueryFunction, QueryFunctionContext, useQuery } from '@tanstack/react-query';
import { api } from '../../index';
import NameAndId from '../../interfaces/NameAndId';

const pageSize = 10;
const emptyResult: NameAndId[] = [];

const getProjectList: QueryFunction<NameAndId[]> = async ({ queryKey }: QueryFunctionContext) => {
	if (!queryKey[0]) {
		return emptyResult;
	}
	let url = `/api/tlo/projects?searchTerm=${encodeURIComponent(queryKey[0] as string)}&count=${pageSize}`;
	if (queryKey[1]) {
		url += `&clientId=${queryKey[1]}`;
	}
	const response = await api.get<NameAndId[]>(url);
	if (response.status === 200) {
		return response.data;
	}
	throw new Error(response.data as any);
};


const useGetProjectList = (searchTerm?: string, clientId?: string) => {
	const { data, isLoading, error } = useQuery({
		queryKey: [searchTerm, clientId, 'getProjectList'],
		queryFn: getProjectList,
		enabled: !!searchTerm,
	});

	return {
		projectResults: data || emptyResult,
		isLoading,
		error,
	};
};

export default useGetProjectList;
