import React, { useState } from 'react';
import Button from './Button';
import PremadePromptCard from './PremadePromptCard';
import PremadePromptResponse from '../interfaces/response/PremadePromptResponse';
import { useTranslation } from 'react-i18next';
import { useAppSelector } from '../redux/useAppSelector';

type Props = {
	onSelectPrompt: (prompt: string) => void;
	onCancel: () => void;
};

const PremadePromptsModal: React.FC<Props> = ({ onCancel, onSelectPrompt }) => {
	const { t } = useTranslation();
	const [selectedPrompt, setSelectedPrompt] = useState<PremadePromptResponse>();
	const [promptFilter, setPromptFilter] = useState<string>('');
	const { chatConfiguration: { premadePrompts } } = useAppSelector(state => state.conversations);

	const onCancelClicked = () => {
		onCancel();
	};

	const stopPropagation: React.MouseEventHandler<HTMLDivElement> = (evt) => {
		evt.stopPropagation();
		evt.preventDefault();
	};

	const onSelectPromptClicked = () => {
		if (!selectedPrompt) return;
		onSelectPrompt(selectedPrompt.content);
	};

	const onChangeFilter: React.ChangeEventHandler<HTMLInputElement> = (evt) => {
		setPromptFilter(evt.target.value);
		setSelectedPrompt(undefined);
	};

	return (
		<div className=" absolute top-0 left-0 bottom-0 right-0 flex-col  items-center justify-center bg-[#00000030] z-20" onClick={onCancelClicked}>
			<div className="bg-white p-4 shadow-2xl shadow-black flex-col rounded-lg space-y-8  h-3/5 w-1/2" onClick={stopPropagation}>
				<div className="flex-row justify-between">
					<div className="text-primary text-lg  font-semibold pl-4">{t('chatPage.premadePrompts.title')}</div>
					<input type={'text'} placeholder={t('chatPage.premadePrompts.searchPrompt')} className="shadow outline-none pl-4 pr-4 text-sm rounded-lg pt-1 pb-1" onChange={onChangeFilter} />
				</div>
				<div className="grid md:grid-cols-3 grid-cols-1 gap-4 overflow-scroll p-4 flex-1">
					{premadePrompts
						.filter((p) => p.title.toLowerCase().includes(promptFilter.toLowerCase()))
						.map((prompt) => (
							<PremadePromptCard content={prompt.content} title={prompt.title} key={prompt.id} onSelectPrompt={() => setSelectedPrompt(prompt)} isSelected={selectedPrompt?.id === prompt.id} />
						))}
				</div>
				<div className="justify-end w-full flex-row">
					<div className="space-x-4 flex-row w-64">
						<Button onClick={onCancelClicked} title={t('chatPage.premadePrompts.cancel')} />
						<Button onClick={onSelectPromptClicked} title={t('chatPage.premadePrompts.apply')} colored disabled={!selectedPrompt} />
					</div>
				</div>
			</div>
		</div>
	);
};

export default PremadePromptsModal;
