import { PropagateLoader, SyncLoader } from 'react-spinners';
import React from 'react';

type Props = {
	loading?: boolean;
	spinner?: boolean;
};

const Loading: React.FC<Props> = ({ loading, spinner }) => {
	if (spinner) {
		return <PropagateLoader color={'#323046'} loading={loading} size={10} className="justify-center items-center" />;
	}
	return <SyncLoader color={'#323046'} loading={loading} size={10} className="justify-center items-center" />;
};

export default Loading;
