import { HttpTransportType, HubConnection, HubConnectionBuilder, HubConnectionState, IHttpConnectionOptions, JsonHubProtocol, LogLevel } from '@microsoft/signalr';
import ChatMessageResponseInterface from '../../interfaces/response/ChatMessageResponseInterface';
import { StoreMiddlewareAPI } from '../store';
import { addMessageToConversationFromServer, updateBotResponseStatus, updateMessageProperty } from '../states/conversations/conversationsSlice';
import { api } from '../../index';

const enum SignalRCallbackMethods {
	ReceiveMessage = 'ReceiveMessage',
	ReceiveMessageUpdate = 'ReceiveMessageUpdate',
	ReceiveBotResponseStatus = 'ReceiveBotResponseStatus',
}

const setupSignalRConnectionToChatHub = (store: StoreMiddlewareAPI) => {
	const connectionHubUrl = new URL('/message-hub', api.defaults.baseURL);
	const connectionOptions: IHttpConnectionOptions = {
		skipNegotiation: true,
		transport: HttpTransportType.WebSockets,
		logger: LogLevel.Warning,
		accessTokenFactory(): string | Promise<string> {
			const activeUserInfo = store.getState().app.activeUserInfo;
			if (!activeUserInfo) {
				throw new Error('No active user found.');
			}
			return activeUserInfo.accessToken;
		},
	};

	const hubConnection = new HubConnectionBuilder()
		.withUrl(connectionHubUrl.toString(), connectionOptions)
		.withAutomaticReconnect()
		.withHubProtocol(new JsonHubProtocol())
		.configureLogging(LogLevel.Information)
		.build();

	hubConnection.serverTimeoutInMilliseconds = 60000;

	return hubConnection;
};

const registerCommonSignalConnectionEvents = (hubConnection: HubConnection) => {
	// Re-establish the connection if connection dropped
	hubConnection.onclose((error) => {
		if (hubConnection.state === HubConnectionState.Disconnected) {
			const errorMessage = 'Connection closed due to error. Try refreshing this page to restart the connection';
			console.log(errorMessage, error);
		}
	});

	hubConnection.onreconnecting((error) => {
		if (hubConnection.state === HubConnectionState.Reconnecting) {
			const errorMessage = 'Connection lost due to error. Reconnecting...';
			console.log(errorMessage, error);
		}
	});

	hubConnection.onreconnected((connectionId = '') => {
		if (hubConnection.state === HubConnectionState.Connected) {
			const message = 'Connection reestablished. Please refresh the page to ensure you have the latest data.';
			console.log(message + ` Connected with connectionId ${connectionId}`);
		}
	});
};

const startSignalRConnection = (hubConnection: HubConnection, store: StoreMiddlewareAPI) => {
	registerCommonSignalConnectionEvents(hubConnection);
	hubConnection
		.start()
		.then(() => {
			console.assert(hubConnection.state === HubConnectionState.Connected);
			console.log('SignalR connection established');
		})
		.catch((err) => {
			console.assert(hubConnection.state === HubConnectionState.Disconnected);
			console.error('SignalR Connection Error: ', err);
			setTimeout(() => {
				startSignalRConnection(hubConnection, store);
			}, 5000);
		});
};

const registerSignalREvents = (hubConnection: HubConnection, store: StoreMiddlewareAPI) => {
	hubConnection.on(SignalRCallbackMethods.ReceiveMessage, (chatId: string, message: ChatMessageResponseInterface) => {
		store.dispatch(addMessageToConversationFromServer({
			chatId,
			message,
		}));
		},
	);

	hubConnection.on(SignalRCallbackMethods.ReceiveMessageUpdate, (message: ChatMessageResponseInterface) => {
		store.dispatch(updateMessageProperty({
			chatId: message.chatId,
			messageIdOrIndex: message.id,
			property: message.tokenUsage ? 'tokenUsage' : 'content',
			value: message.tokenUsage ?? message.content,
			updatedContent: message.content,
		}));
	});

	hubConnection.on(SignalRCallbackMethods.ReceiveBotResponseStatus, (chatId: string, status: string | undefined) => {
		store.dispatch(updateBotResponseStatus({ chatId, status }));
	});
};


let hubConnection: HubConnection | undefined = undefined;

export const getOrCreateHubConnection = (store: StoreMiddlewareAPI) => {
	if (hubConnection === undefined) {
		hubConnection = setupSignalRConnectionToChatHub(store);

		startSignalRConnection(hubConnection, store);
		registerSignalREvents(hubConnection, store);
	}
	return hubConnection;
};