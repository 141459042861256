import { Action, combineReducers, createAction, Reducer } from '@reduxjs/toolkit';
import { RootState } from './store';
import conversationsReducer from './states/conversations/conversationsSlice';
import appReducer from './states/app/appSlice';

export const resetApp = createAction('resetApp');

const rootReducer: Reducer<RootState> = combineReducers({
	conversations: conversationsReducer,
	app: appReducer,
});

export const resetStateReducer = (state: RootState | undefined, action: Action) => {
	if (action.type === resetApp.type) {
		state = {
			app: appReducer(undefined, action),
			conversations: conversationsReducer(undefined, action),
		};
	}

	return rootReducer(state, action);
};