import { QueryFunction, QueryFunctionContext, useQuery } from '@tanstack/react-query';
import { api } from '../../index';
import NameAndId from '../../interfaces/NameAndId';

const pageSize = 10;
const emptyResult: NameAndId[] = [];

const getClientList: QueryFunction<NameAndId[]> = async ({ queryKey }: QueryFunctionContext) => {
	if (!queryKey[0]) {
		return emptyResult;
	}
	const response = await api.get<NameAndId[]>(`/api/tlo/clients?searchTerm=${encodeURIComponent(queryKey[0] as string)}&count=${pageSize}`);
	if (response.status === 200) {
		return response.data;
	}
	throw new Error(response.data as any);
};


const useGetClientList = (searchTerm?: string, accessToken?: string) => {
	const { data, isLoading, error } = useQuery({
		queryKey: [searchTerm, 'getClientList'],
		queryFn: getClientList,
		enabled: !!searchTerm,
	});

	return {
		clientResults: !!searchTerm ? (data || emptyResult) : emptyResult,
		isLoading,
		error,
	};
};

export default useGetClientList;
