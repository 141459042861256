import OptionSectionTemplateConfigurationResponse from '../../interfaces/response/OptionSectionTemplateConfigurationResponse';
import React from 'react';
import DocumentGenerationOption from './DocumentGenerationOption';
import { makeStyles, Text, tokens } from '@fluentui/react-components';

type Props = {
	section: OptionSectionTemplateConfigurationResponse;
	setSelectedOptionIds: React.Dispatch<React.SetStateAction<string[]>>;
	selectedOptionIds: string[];
	setData: React.Dispatch<React.SetStateAction<any>>;
}

const useClasses = makeStyles({
	container: {
		flexDirection: 'column',
	},
	sectionName: {
		fontSize: tokens.fontSizeBase400,
		fontWeight: tokens.fontWeightSemibold,
		marginBottom: tokens.spacingVerticalM,
	},
	column: {
		flexDirection: 'column',
	},
});

const DocumentGenerationOptionSection: React.FC<Props> = ({ section, setSelectedOptionIds, selectedOptionIds, setData }) => {
	const classes = useClasses();
	return (
		<div className={classes.container}>
			<Text className={classes.sectionName}>{section.name}</Text>
			<div className={classes.column}>
				{section.options.map(option => <DocumentGenerationOption
					section={section.id}
					setSelectedOptionIds={setSelectedOptionIds}
					selectedOptionIds={selectedOptionIds}
					key={option.id}
					setData={setData}
					option={option} />,
				)}
			</div>
		</div>
	);
};

export default DocumentGenerationOptionSection;