import BaseService from './BaseService';
import UpdateChatUserFeedbackRequestInterface from '../interfaces/request/UpdateChatUserFeedbackRequestInterface';
import ChatConfigurationResponse from '../interfaces/response/ChatConfigurationResponseInterface';
import ChatMessageResponseInterface from '../interfaces/response/ChatMessageResponseInterface';
import ChatSessionResponseInterface from '../interfaces/response/ChatSessionResponseInterface';
import CreateNewChatResponseInterface from '../interfaces/response/CreateNewChatResponseInterface';
import ChatRequestInterface from '../interfaces/request/ChatRequestInterface';
import GenerateChatResponseInterface from '../interfaces/response/GenerateChatResponseInterface';

export class ChatService extends BaseService {

	public updateUserFeedbackAsync = async (body: UpdateChatUserFeedbackRequestInterface, accessToken: string): Promise<void> => {
		await this.getResponseAsync({
			commandPath: `chat/feedback`,
			method: 'PUT',
			body,
		}, accessToken);
	};

	public getChatConfigurationAsync = async (accessToken: string): Promise<ChatConfigurationResponse> => {
		return await this.getResponseAsync<ChatConfigurationResponse>({
			commandPath: `chat/configuration`,
			method: 'GET',
		}, accessToken);
	};

	public getChatHistoryAsync = async (chatId: string, accessToken: string): Promise<ChatMessageResponseInterface[]> => {
		const query = new URLSearchParams();
		query.append('chatId', chatId);
		const result = await this.getResponseAsync<ChatMessageResponseInterface[]>(
			{
				commandPath: `chat/history`,
				method: 'GET',
				query,
			}, accessToken);

		return result.reverse();
	};

	public getChatSessionsAsync = async (accessToken: string): Promise<ChatSessionResponseInterface[]> => {
		return await this.getResponseAsync<ChatSessionResponseInterface[]>({
			commandPath: 'chats',
			method: 'GET',
		}, accessToken);
	};

	public createNewChatAsync = async (accessToken: string): Promise<CreateNewChatResponseInterface> => {
		return await this.getResponseAsync<CreateNewChatResponseInterface>({
			commandPath: 'chat',
			method: 'PUT',
		}, accessToken);
	};

	public deleteChatAsync = async (chatId: string, accessToken: string): Promise<void> => {
		const query = new URLSearchParams();
		query.append('chatId', chatId);
		return await this.getResponseAsync({
			commandPath: 'chat',
			query,
			method: 'DELETE',
		}, accessToken);
	};

	public generateResponseAsync = async (chatId: string, body: ChatRequestInterface, accessToken: string): Promise<GenerateChatResponseInterface> => {
		const query = new URLSearchParams();
		query.append('chatId', chatId);
		return await this.getResponseAsync({
			commandPath: 'chat',
			query,
			method: 'POST',
			body,
		}, accessToken);
	};
}