import React from 'react';
import { useAppDispatch } from '../redux/useAppSelector';
import { setSelectedConversation } from '../redux/states/conversations/conversationsSlice';
import useChat from '../hooks/useChat';
import { ChatState } from '../redux/states/conversations/ChatState';

type Props = {
	chatSession: ChatState;
	isActive: boolean;
}

const chatSessionTime = (session: ChatState) => {
	const dateObject = new Date(session.createdOn);
	const options: Intl.DateTimeFormatOptions = {
		hour: '2-digit',
		minute: '2-digit',
	};
	return dateObject.toLocaleTimeString('nl-BE', options);
};

const ChatHistoryDayEntry: React.FC<Props> = ({ chatSession, isActive }) => {
	const dispatch = useAppDispatch();
	const chat = useChat();

	const onSessionClicked = () => {
		dispatch(setSelectedConversation(chatSession.id));
	};

	const onDeleteClicked = () => {
		chat.deleteChat(chatSession.id);
	};

	return (
		<div>
			<div key={chatSession.id} className={`${isActive && 'font-semibold'} hover:underline cursor-pointer flex-1 select-none`}
				  onClick={onSessionClicked}>{chatSession.title} - {chatSessionTime(chatSession)}</div>
			<div className={'cursor-pointer mr-4 select-none'} onClick={onDeleteClicked}>
				<span className="material-symbols-outlined justify-end text-red">delete</span>
			</div>
		</div>
	);
};

export default ChatHistoryDayEntry;