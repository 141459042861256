import React, { Fragment, useMemo } from 'react';
import { makeStyles, mergeClasses, ProgressBar, Text, tokens } from '@fluentui/react-components';

type Props = {
	stepCount: number;
	currentStep: number;
}

const useClasses = makeStyles({
	container: {
		flexDirection: 'row',
		alignItems: 'center',
		marginLeft: tokens.spacingHorizontalXL,
		marginRight: tokens.spacingHorizontalXL,
	},
	progressBar: {
		flex: 1,
		marginLeft: tokens.spacingHorizontalS,
		marginRight: tokens.spacingHorizontalS,
	},
});

const useStepClasses = makeStyles({
	container: {
		width: '3em',
		height: '3em',
		borderRadius: '1.5em',
		backgroundColor: tokens.colorNeutralForegroundDisabled,
		justifyContent: 'center',
		alignItems: 'center',
		color: tokens.colorNeutralBackgroundDisabled,
		flexShrink: 0,
	},
	text: {
		fontSize: tokens.fontSizeBase400,
		fontWeight: tokens.fontWeightBold,
	},
	colored: {
		backgroundColor: tokens.colorBrandForeground1,
		color: tokens.colorNeutralBackgroundDisabled,
	},
});

const Step: React.FC<{ label: number, active: boolean }> = ({ label, active }) => {
	const classes = useStepClasses();
	return (
		<div className={mergeClasses(classes.container, active && classes.colored)}>
			<Text className={classes.text}>{label}</Text>
		</div>
	);
};

const Stepper: React.FC<Props> = ({ currentStep, stepCount }) => {
	const classes = useClasses();
	const steps = useMemo(() => {
		const list: number[] = [];
		for (let i = 0; i < stepCount; i++) {
			list.push(i);
		}
		return list;
	}, [stepCount]);

	return (
		<div className={classes.container}>
			{steps.map(step => {
				const stepElement = <Step label={step + 1} active={currentStep >= step} />;
				if (step !== stepCount - 1) {
					return (
						<Fragment key={step}>
							{stepElement}
							<ProgressBar className={classes.progressBar} value={currentStep > step ? 1 : 0} />
						</Fragment>
					);
				}
				return stepElement;
			})}
		</div>
	);
};

export default Stepper;