export interface ActiveUserInfo {
	id: string;
	username: string;
	accessToken: string;
}

export type AppState = {
	activeUserInfo?: ActiveUserInfo;
	isMaintenance: boolean;
	state: AppStateState
}

export enum AppStateState {
	SettingUserInfo,
	SettingLanguage,
	LoadingChats,
	Ready,
	Error
}

export const initialState: AppState = {
	isMaintenance: false,
	state: AppStateState.SettingUserInfo,
};