import React from 'react';
import OptionSectionApiTemplateConfigurationResponse from '../../interfaces/response/OptionSectionApiTemplateConfigurationResponse';
import useGetDocumentApiOptionsList from '../../hooks/queries/useGetDocumentApiOptionsList';
import DocumentGenerationOption from './DocumentGenerationOption';
import { makeStyles, Spinner, Text, tokens } from '@fluentui/react-components';

type Props = {
	section: OptionSectionApiTemplateConfigurationResponse;
	setSelectedOptionIds: React.Dispatch<React.SetStateAction<string[]>>;
	selectedOptionIds: string[];
	setData: React.Dispatch<React.SetStateAction<any>>;
}

const useClasses = makeStyles({
	container: {
		flexDirection: 'column',
	},
	sectionName: {
		fontSize: tokens.fontSizeBase400,
		fontWeight: tokens.fontWeightSemibold,
		marginBottom: tokens.spacingVerticalM,
	},
});

const DocumentGenerationOptionSectionApi: React.FC<Props> = ({ section, selectedOptionIds, setSelectedOptionIds, setData }) => {
	const options = useGetDocumentApiOptionsList(section.endpoint);
	const classes = useClasses();
	return (
		<div className={classes.container}>
			<Text className={classes.sectionName}>{section.name}</Text>
			{options.isLoading ? <Spinner /> : options.results.map(option => {
				return <DocumentGenerationOption setData={setData} section={section.id} selectedOptionIds={selectedOptionIds} setSelectedOptionIds={setSelectedOptionIds} key={option.id}
															option={option} />;
			})}
		</div>
	);
};

export default DocumentGenerationOptionSectionApi;