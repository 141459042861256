import { BrandVariants, createDarkTheme, createLightTheme, Theme } from '@fluentui/react-components';

const uegBrand: BrandVariants = {
	10: '#030204',
	20: '#16161D',
	30: '#242231',
	40: '#2F2D42',
	50: '#3C394F',
	60: '#48465B',
	70: '#555366',
	80: '#636072',
	90: '#706E7F',
	100: '#7E7C8B',
	110: '#8C8A98',
	120: '#9A99A5',
	130: '#A9A7B2',
	140: '#B8B6BF',
	150: '#C6C5CD',
	160: '#D6D5DA',
};

const lightTheme: Theme = {
	...createLightTheme(uegBrand),
};

const darkTheme: Theme = {
	...createDarkTheme(uegBrand),
};


darkTheme.colorBrandForeground1 = uegBrand[110];
darkTheme.colorBrandForeground2 = uegBrand[120];

export { lightTheme, darkTheme };