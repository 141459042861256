import Markdown, { Components, defaultUrlTransform } from 'react-markdown';
import Button from './Button';
import Badge from './Badge';
import React, { memo, useMemo, useState } from 'react';
import remarkGfm from 'remark-gfm';
import ChatMessageResponseInterface from '../interfaces/response/ChatMessageResponseInterface';
import { useTranslation } from 'react-i18next';

type Props = {
	message: ChatMessageResponseInterface;
	showBadge: boolean;
	hideFeedbackButtons?: boolean;
	onChangeFeedback: (positive: boolean, messageId: string) => void;
};

const renderers: Partial<Components> = {
	// eslint-disable-next-line
	a: (props) => <b className={'hover:underline'}><a {...props} target={'_blank'} /></b>,
};

const ChatMessage: React.FC<Props> = ({ message, showBadge, hideFeedbackButtons, onChangeFeedback }) => {
	const citations = useMemo(() => (message.citations ?? []), [message]);
	const [isPositiveFeedback, setIsPositiveFeedback] = useState<boolean | undefined>(message.isPositiveFeedback);
	const [showNegativeFeedbackMessage, setShowNegativeFeedbackMessage] = useState<boolean>(false);
	const { t } = useTranslation();

	const onCopyClick = async () => {
		await navigator.clipboard.writeText(message.content);
	};

	const onThumbsUpClick = () => {
		onChangeFeedback(true, message.id!);
		setIsPositiveFeedback(true);
	};

	const onThumbsDownClick = () => {
		onChangeFeedback(false, message.id!);
		setIsPositiveFeedback(false);
		setShowNegativeFeedbackMessage(true);
	};

	const onClickSource = (url: string) => {
		window.open(url, '_blank');
	};

	return (
		<div className={`mt-2 relative ${message.authorRole.label === 'user' ? 'flex-row-reverse' : 'flex-row'} ${showBadge && 'mt-6'}`}>
			<div className={`mr-2 ${message.authorRole.label === 'user' ? 'ml-2' : 'mr-4'}  mt-1 w-8 h-8 flex-shrink-0`}>{showBadge && <Badge isUser={message.authorRole.label === 'user'} />}</div>
			<div className={`flex-col rounded-md `}>
				<div className={`shadow p-2 flex-col rounded-xl mr-2 pl-3 pr-3 ${message.authorRole.label === 'user' ? 'bg-secondary' : 'bg-white'}`}>
					<Markdown remarkPlugins={[remarkGfm]} urlTransform={defaultUrlTransform} components={renderers}>
						{message.content}
					</Markdown>
					<p className={'mt-4 font-bold'}>
						{showNegativeFeedbackMessage && t('chatPage.negativeFeedbackContentMessage')}
					</p>
					{message.authorRole.label !== 'user' && !hideFeedbackButtons && <div className={'mt-2 text-sm flex-row text-primary space-x-2 justify-end items-center'}>
						<span className={`material-symbols-outlined text-2xl opacity-50 hover:opacity-100 cursor-pointer select-none`} onClick={onCopyClick}>content_copy</span>
						<span className={`material-symbols-outlined text-2xl ${!isPositiveFeedback ? 'opacity-50' : 'icon-fill'} hover:opacity-100 cursor-pointer select-none`}
								onClick={onThumbsUpClick}>thumb_up</span>
						<span
							className={`material-symbols-outlined text-2xl ${(isPositiveFeedback === null || isPositiveFeedback) ? 'opacity-50' : 'icon-fill'} hover:opacity-100 cursor-pointer select-none`}
							onClick={onThumbsDownClick}>thumb_down</span>
					</div>}
				</div>

				{citations.length > 0 && (
					<div className="flex-row  mt-2 mb-2 text-sm space-x-2">
						{citations.map((citation, index) => (
							<div className="w-56" key={index}>
								<Button onClick={() => onClickSource(citation.link)} title={citation.sourceName} key={index} colored />
							</div>
						))}
					</div>
				)}
			</div>
		</div>
	);
};

export default memo(ChatMessage, (prevProps, nextProps) => prevProps.message.id === nextProps.message.id && prevProps.message.content === nextProps.message.content);
