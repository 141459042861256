import React, { useCallback, useEffect, useState } from 'react';
import { OptionTemplateConfiguration } from '../../interfaces/response/OptionSectionTemplateConfigurationResponse';
import { Checkbox, Dropdown, Input, Label, makeStyles, Option, OptionOnSelectData, SelectionEvents, Text, Textarea, tokens, Tree, TreeItem, TreeItemLayout } from '@fluentui/react-components';

type Props = {
	section: string;
	option: OptionTemplateConfiguration;
	setSelectedOptionIds: React.Dispatch<React.SetStateAction<string[]>>;
	selectedOptionIds: string[];
	setData: React.Dispatch<React.SetStateAction<any>>;
}

const useClasses = makeStyles({
	column: {
		flexDirection: 'column',
		gap: '2px',
		marginBottom: tokens.spacingVerticalM,
	},
});

const DocumentGenerationOptionCheckbox: React.FC<Props> = ({ option, selectedOptionIds, setSelectedOptionIds, section, setData }) => {
	const toOptionSectionId = useCallback((id: string) => `${section}.${id}`, [section]);
	const [selectedIds, setSelectedIds] = useState<string[]>([]);

	const updateData = useCallback((ids: string[], enable: boolean) => {
		setData((data: any) => {
			if (enable) {
				if (!data[section]) {
					data[section] = {};
				}
				ids.forEach(id => data[section][id] = '');
			} else {
				if (!data[section]) return data;
				ids.forEach(id => delete data[section][id]);
				if (Object.entries(data[section]).length === 0) delete data[section];
			}
			return data;
		});
		setSelectedOptionIds(prev => {
			if (enable) {
				return [...prev, ...ids.map(toOptionSectionId)];
			} else {
				return prev.filter(id => !ids.map(toOptionSectionId).includes(id));
			}
		});
	}, [setSelectedOptionIds, setData, section, toOptionSectionId]);

	const toggleSelectedIdsWithParent = useCallback((parents: OptionTemplateConfiguration[], selectedIds: string[]): string[] => {
		const newList = parents.reduceRight((acc, currentValue, index) => {
			if (acc.findIndex(selectedId => currentValue.children.map(child => child.id).includes(selectedId)) === -1) {
				return acc.filter(c => c !== currentValue.id);
			} else if (!acc.includes(currentValue.id)) {
				return [...acc, currentValue.id];
			}
			return acc;
		}, selectedIds);
		if (selectedIds.length > newList.length) {
			return selectedIds.filter(id => !newList.includes(id));
		} else {
			return newList.filter(id => !selectedIds.includes(id));
		}
	}, []);

	const toggleSelected = (enable: boolean, option: OptionTemplateConfiguration, parents: OptionTemplateConfiguration[]) => {
		if (enable) {
			if (selectedIds.includes(option.id)) return;
			const newSelectedIds = [...selectedIds, option.id];
			const parentIdsToToggle = toggleSelectedIdsWithParent(parents, [...selectedIds, option.id]);
			setSelectedIds([...newSelectedIds, ...parentIdsToToggle]);
			updateData([...parentIdsToToggle, option.id], true);
		} else {
			if (!selectedIds.includes(option.id)) return;
			const newSelectedIds = selectedIds.filter(c => c !== option.id);
			const parentIdsToToggle = toggleSelectedIdsWithParent(parents, newSelectedIds);
			setSelectedIds(selectedIds.filter(id => !parentIdsToToggle.includes(id) && id !== option.id));
			updateData([...parentIdsToToggle, option.id], false);
		}
	};

	const renderOptions = (options: OptionTemplateConfiguration[], parents: OptionTemplateConfiguration[]) => {
		if (options.length === 0) return null;
		return (
			<Tree aria-label={toOptionSectionId(option.id)}>
				{options.map(option => {
					const onChange: React.ChangeEventHandler<HTMLInputElement> = (event) => {
						toggleSelected(event.target.checked, option, parents);
					};
					return (
						<TreeItem itemType={option.children.length === 0 ? 'leaf' : 'branch'} key={option.id}>
							<TreeItemLayout>
								{option.children.length === 0 ? (
									<Checkbox label={option.name} checked={selectedIds.includes(option.id)} onChange={onChange} />
								) : (
									<Text>{option.name}</Text>
								)}
							</TreeItemLayout>
							{renderOptions(option.children, [...parents, option])}
						</TreeItem>
					);
				})}
			</Tree>
		);
	};

	return option.children.length > 0 ?
		renderOptions([option], []) :
		<Checkbox label={option.name} checked={selectedIds.includes(option.id)} onChange={ev => toggleSelected(ev.target.checked, option, [])} />;
};

const DocumentGenerationOptionText: React.FC<Props> = ({ option, section, setData }) => {
	const [content, setContent] = useState<string>('');
	const classes = useClasses();

	useEffect(() => {
		setData((data: any) => {
			if (!data[section]) {
				data[section] = {};
			}
			data[section][option.id] = content;
			return data;
		});
	}, [content, setData, option, section]);

	const updateContentTextArea: React.ChangeEventHandler<HTMLTextAreaElement> = (event) => {
		setContent(event.target.value);
	};

	const updateContentTextInput: React.ChangeEventHandler<HTMLInputElement> = (event) => {
		setContent(event.target.value);
	};

	return (
		<div className={classes.column}>
			<Label>{option.name}</Label>
			{option.type === 'Text' ? <Input value={content} onChange={updateContentTextInput} /> : <Textarea value={content} onChange={updateContentTextArea} />}
		</div>
	);
};


const DocumentGenerationOptionDropdown: React.FC<Props> = ({ option, section, setData }) => {
	const classes = useClasses();
	const [selectedOptionIndex, setSelectedIndexOption] = useState<number>(0);

	useEffect(() => {
		setData((data: any) => {
			if (!data[section]) {
				data[section] = {};
			}
			data[section][option.id] = option.values[selectedOptionIndex];
			return data;
		});
	}, [setData, option, section, selectedOptionIndex]);

	const onOptionSelect = (_: SelectionEvents, data: OptionOnSelectData) => {
		const index = option.values.findIndex(value => value === data.optionValue);
		setSelectedIndexOption(index);
	};

	return (
		<div className={classes.column}>
			<Label>{option.name}</Label>
			<Dropdown defaultValue={option.values[0]} onOptionSelect={onOptionSelect}>
				{option.values.map(value => (
					<Option key={value}>
						{value}
					</Option>
				))}
			</Dropdown>
		</div>
	);
};

const DocumentGenerationOption: React.FC<Props> = (props) => {
	switch (props.option.type) {
		case 'Checkbox':
			return <DocumentGenerationOptionCheckbox {...props} />;
		case 'TextArea':
		case 'Text':
			return <DocumentGenerationOptionText {...props} />;
		case 'Dropdown':
			return <DocumentGenerationOptionDropdown {...props} />;
	}
};

export default DocumentGenerationOption;