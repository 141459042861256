import Slider from './Slider';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useAppSelector } from '../redux/useAppSelector';
import { ChatSessionConfigurationResponseInterface } from '../interfaces/response/ChatSessionResponseInterface';

type Props = {
	configuration: ChatSessionConfigurationResponseInterface;
	onConfigurationChange: (configuration: ChatSessionConfigurationResponseInterface) => void;
};

const ChatConfiguration: React.FC<Props> = ({ configuration, onConfigurationChange }) => {
	const { t } = useTranslation();
	const { chatConfiguration: { availableSources } } = useAppSelector(state => state.conversations);
	
	const setMaxResponseLength = (value: number) => {
		onConfigurationChange({ ...configuration, maxResponseLength: value });
	};

	const setDocumentCount = (value: number) => {
		onConfigurationChange({ ...configuration, documentCount: value });
	};

	const setScoringCutoff = (value: number) => {
		onConfigurationChange({ ...configuration, scoringCutoff: value });
	};

	const toggleSource = (id: string) => {
		if (configuration.informationSources.includes(id)) {
			onConfigurationChange({ ...configuration, informationSources: configuration.informationSources.filter(c => c !== id) });
		} else {
			onConfigurationChange({ ...configuration, informationSources: [...configuration.informationSources, id] });
		}
	};

	return (
		<div className="justify-center flex-1 flex-col ">
			<div className={'space-y-2 flex-col'}>
				<div className={'flex-row justify-between items-center font-semibold text-xl -mt-2'}>
					<p>{t('chatPage.chatConfiguration.title')}</p>
					<span className="material-symbols-outlined text-xl align-text-bottom mt-2">segment</span>
				</div>
				<div className="flex-col">
					{t('chatPage.chatConfiguration.maxTokenLength')}
					<Slider value={configuration.maxResponseLength} onValueChange={setMaxResponseLength} useInteger minValue={1} maxValue={1000} />
				</div>
				<div className="flex-col">
					{t('chatPage.chatConfiguration.precision')}
					<Slider minValue={0.8} maxValue={0.98} value={configuration.scoringCutoff} onValueChange={setScoringCutoff} />
				</div>
				<div className="flex-col">
					{t('chatPage.chatConfiguration.referenceDocumentCount')}
					<Slider minValue={0} maxValue={5} value={configuration.documentCount} onValueChange={setDocumentCount} useInteger />
				</div>
			</div>
			<div className={'flex-col space-y-1'}>
				<div className={'flex-row justify-between items-center font-semibold text-xl mt-4'}>
					<p>{t('chatPage.chatConfiguration.sources')}</p>
					<span className="material-symbols-outlined text-xl mt-2">find_in_page</span>
				</div>
				{availableSources.map(c => (
					<div className={'flex-row space-x-2 text-base items-center'} key={c.id}>
						<input type={'checkbox'} className={'w-5 h-5 accent-primary'} checked={configuration.informationSources.includes(c.id)} onChange={() => toggleSource(c.id)} />
						<p>{c.name}</p>
					</div>
				))}
			</div>
		</div>
	);
};

export default ChatConfiguration;
